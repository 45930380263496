@import '~bulma/bulma.sass';

$brand-dark-grey: #242323;
$brand-purple: #ac6fde;
$font-english: 'Roboto Slab', serif;
$font-japanese: 'Noto Sans JP', sans-serif;

* {
  font-family: $font-english, $font-japanese;
  box-sizing: border-box;
}

.navbar-item {
  font-weight: 500;
}

.navbar-item p:hover {
  color: $brand-purple;
  cursor: pointer;
}

.translate {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.navbar-burger {
  color: $brand-purple;
}

hr {
  background-color: $brand-purple;
}

h2, p {
  line-break: strict;
}

.social-icon {
  margin: 0px 5px;
}

.social-link {
  display: inline;
}

.software-icons  {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  li {
    justify-content: center;
    border: 1px solid rgba(0,0,0,.125);
    margin: 20px 5px 5px 5px;
    width: 100px;
    height: 100px;
    align-items: center;
    font-size: 13px;
    i {
      font-size: 50px;
    }
  }
}

.cell {
  display: flex;
  flex-direction: column;
}

.cell:hover {
  color: $brand-purple;
  i {
    transition: all 0.65s;
    transform: rotateY(360deg);
  }
}

.dark {
  background-color: $brand-dark-grey;
  color: white;
  h1, p {
    color: white;
  }
}

.purple-text {
  color: $brand-purple;
}

.proj-text-container {
  height: fit-content;
  p {
    height: fit-content;
  }
}

.proj-button {
  font-size: 20px;
  margin: 5px 0px 0px 5px;
  color: white;
}

.proj-button:hover {
  color: $brand-purple;
}

.proj-techs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0px;
  i {
    font-size: 50px;
    margin: 5px 3px;
  }
}

.arrow-buttons {
  display: flex;
  justify-content: space-evenly;
  p {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

.arrow {
  font-size: 30px;
  font-weight: 20em;
}

.arrow:hover {
  color: $brand-purple;
  cursor: pointer;
}

.proj-index {
  font-size: 20px;
  align-content: center;
}

.image-card {
  position: relative;
  background-size: cover;
  padding: 0;
  height: 20em;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(36, 35, 34, 0.75); /* Tint color */
  background-blend-mode: multiply;
  h2, p {
    color: white;
    padding: 0px 15px;
  }
  h2 {
    font-size: 20px;
    font-weight: bolder;
  }
  p {
    margin-bottom: 20px;
  }
}

.language {
  background-image: url('../assets/書道.jpg');
}
.music {
  background-image: url('../assets/jazz-bass.jpg');
}
.bouldering {
  background-image: url('../assets/bouldering.jpg');
}

.socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin: 5px 0px;
  }
}

@media only screen and (min-width:1025px) {
  section {
    min-height: 100vh;
  }

  .contact-button {
    p {
      min-width: 86px;
      text-align: center;
    }
  }
  
  .hero-title {
    font-size: 70px;
  }

  .hero-subtitle {
    font-size: 30px;
  }

  .proj-text-container {
    max-width: 75%;
  }

  .software-icons {
    justify-content: left;
  }

  .socials {
    margin-top: 8rem;
  }
}

@media only screen and (min-width:600px) {
  .proj-techs {
    justify-content: center;
    i {
      font-size: 45px;
    }
  }

  .project-links {
    text-align: center;
  }
}

@media only screen and (min-width:480px) {
  .proj-techs {
    justify-content: center;
    i {
      font-size: 45px;
    }
  }

  .project-links {
    text-align: center;
  }
}